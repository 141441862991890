// token配置
const token = {
  BPool: '0x53f863e835722533c5832944da75a69f78f0018e' // '0x272fea610efcf539508cbad465998c294f22735e',
};

export const Config = {
  bPoolTokenIn: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  bPoolTokenOut: '0xFb782396c9b20E564A64896181c7AC8d8979d5F4',
};

export const projectConfig = {
  'status': 0,
  'startDate': '2021/9/27 21:00 UTC+8',
  'endDate': '2021/9/28 21:00 UTC+8',
  'showEchartsTime': 180000000,
  'endBlockNum': 13314302, // 27307227,
  'startBlockNum': 13307903,
}


export const bpoolTOken = token.BPool;
