// @ts-nocheck
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { projectConfig } from '../../constants/config'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import { LineChart, BarChart } from 'echarts/charts'
import {
  GridComponent,
  ToolboxComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
} from 'echarts/components';
import {
  CanvasRenderer,
} from 'echarts/renderers';

echarts.use(
  [TitleComponent, TooltipComponent, GridComponent, LegendComponent, ToolboxComponent, LineChart, BarChart, CanvasRenderer]
)
dayjs.extend(utc)

const randomData = (price: number, date: any) => {
	return {
		blockNum: date,
		price: price
	};
}

const Chart = (props: {historyPrice: any}) => {
	const [date, setDate] = useState([])
	const [price, setPrice] = useState()
	const [endList, setEndList] = useState()
	const [endListPrice, setEndListPrice] = useState()
	useEffect(() => {
		if (props.historyPrice !== undefined) {
			setPrice(props.historyPrice.map((i: any) => {
				return (i.price)
			}))
			const historyPriceEnd = props.historyPrice[props.historyPrice.length - 1]
			
			let balanceA = historyPriceEnd === undefined ? 332000 : Number(historyPriceEnd.balA)
			let balanceB = historyPriceEnd === undefined ? 30000000 : Number(historyPriceEnd.balB)
			let weightA = historyPriceEnd === undefined ? 0.1 : Number(historyPriceEnd.weiA)
			let weightB = historyPriceEnd === undefined ? 0.9 :Number(historyPriceEnd.weiB)
			let _tmpPrice: number = historyPriceEnd === undefined ? '0.1' : historyPriceEnd.price
			let historyPriceEndBlockNumber = historyPriceEnd === undefined ? Number(projectConfig.startBlockNum) : historyPriceEnd.blockNum
			const endList = [{
				price: _tmpPrice,
				blockNum: historyPriceEndBlockNumber
			}]

			let start = Number(projectConfig.startBlockNum)
			let end = Number(projectConfig.endBlockNum)
			let pbw = 0.45 / (end - start) // 0.000026147 // 0.0000703125
			let gap = 10

			let futureHeights = []
			for (let i = 0 ; i < (end - start) / gap; i++) {
				futureHeights.push(start + i * gap)
			}

			if ((end - start) % gap !== 0) {
				futureHeights.push(end)
			}
			
			let actualFutureHeights = []

			for (let i = 0; i < futureHeights.length; i++) {
				if (historyPriceEndBlockNumber < futureHeights[i]) {
					actualFutureHeights.push(futureHeights[i])
				}
			}
			
			for(let i = 0; i < actualFutureHeights.length; i++) {
				let deltaW = (actualFutureHeights[i] - start + 1) * pbw
				
				let heightWeight = deltaW + 0.1
				weightA = heightWeight
				weightB = 1 - weightA
				endList.push({
					weightA: weightA,
					price: ((balanceA / weightA) / (balanceB / weightB)).toFixed(6),
					blockNum: actualFutureHeights[i]
				})
			}
			setEndList(endList)
		}
	}, [props])
	useEffect(() => {
		if (props.historyPrice !== undefined) {
			const historyPriceEnd = props.historyPrice[props.historyPrice.length - 1]
			let _dataArr = props.historyPrice.map((i: any) => {
				return i.blockNum
			})
			let _endDataArr = []
			if (endList && endList.length > 0) {
				_endDataArr = endList.map((i: any) => {
					return i.blockNum
				})
				const endListArr = endList.map((i: any) => {
					return i.price
				})
				const _un = props.historyPrice.map((i: any) => {
					return null
				})
				_un.pop()
				const _arr = _un.concat(endListArr)
				setEndListPrice(_arr)
			}
			const _setArr = _dataArr.concat(_endDataArr)
			setDate(_setArr)
		}
	}, [props, endList])
	const _index = props.historyPrice !== undefined && props.historyPrice.length === 0 ? 0 : 1
  const option = {
    color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
    title: {
			text: ''
    },
    tooltip: {
			trigger: 'axis',
			backgroundColor: 'rgba(0, 0, 0, 0.6)',
			borderColor: 'rgba(0, 0, 0, 0)',
			textStyle: {
				color: '#fff'
			},
			axisPointer: {
				type: 'line',
				label: {
					backgroundColor: '#6a7985',
					formatter: function (params) {
						return params.value;
					}
				}
			},
			formatter: (params: any) => {
				const _data = props.historyPrice[params[0].dataIndex]
				if (_data) {
					return `<div>
						<p>$${_data && _data.price}<p>
						<p>#${_data && _data.blockNum}</p>
					</div>`
				} else {
					return `<div>
						<p>$${Number(params[0].value)}<p>
						<p>#${date && date[params[0].dataIndex + _index]}</p>
					</div>`
				}
				
				
			}
    },
    grid: {
			left: '7%',
			right: '2.5%',
			bottom: '3%',
			containLabel: true
    },
    xAxis: [
			{
				type: 'category',
				boundaryGap: false,
				axisLabel: {
					formatter: function (value, index) {
						return '#' + value;
					},
					showMinLabel: true,
					showMaxLabel: true,
					interval: Number((date.length / 4).toFixed(0)),
					// interval: 73,
					show: true,
					 textStyle: {
						 color: '#6A708B',  //更改坐标轴文字颜色
						 fontSize : 12,      //更改坐标轴文字大小
						 padding: [5, 0, 5, 0	]
					 }
				},
				// splitNumber: 2000,
				axisTick: {
					show: false
				},
				axisLine:{
					show: true
				},
				data: date
			}
    ],
    yAxis: [
			{
				position: 'right',
				type: 'value',
				axisLabel: {
					show: true,
					textStyle: {
						color: '#6A708B',  //更改坐标轴文字颜色
						fontSize : 12      //更改坐标轴文字大小
					},
					showMinLabel: false,
				},
				splitLine: {
					show: false
				},
				axisLine:{
					show: true
				},
			}
    ],
    series: [
			{
				name: 'Line 1',
				type: 'line',
				stack: 'Actual',
				// smooth: true,
				lineStyle: {
					width: 2,
					color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
						offset: 0,
						color: '#3F70FF'
					}, {
						offset: 1,
						color: '#06DFAF'
					}])
				},
				showSymbol: false,
				areaStyle: {
					opacity: 0.8,
					color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [{
						offset: 0,
						color: 'rgba(6, 223, 175, 0.5)'
					}, {
						offset: 1,
						color: '#0186EF'
					}])
				},
				emphasis: {
					focus: 'series'
				},
				data: price,
				connectNulls: true,
				clip: true
			},
			{
				name: 'Line 2',
				type: 'line',
				stack: 'Future',
				smooth: false,
				lineStyle: {
					width: 1,
					type: [5, 10],
					color: '#fff'
				},
				showSymbol: false,
				data: endListPrice,
				connectNulls: true,
				clip: true
			}
    ]
}
  return (
		<div>
			<ReactEChartsCore
				echarts={echarts}
				option={option}
			/>
			<p className="bn">Block Number</p>
		</div>
  )
}
export default Chart