import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { projectConfig } from '../constants/config'
import { client } from '../utils/apollo'
dayjs.extend(utc)

export const getHistoryPrice = async () => {
  const pools = gql`
      query ($BlockNumber: Int!) {
        historicalPrices(
          first: 1000, 
          where: {blockNum_gt: $BlockNumber, blockNum_lt: ${projectConfig.endBlockNum + 1}}
          orderBy: blockNum
          orderDirection: asc
        ) {
          id
          ts
          price
          blockNum
          weiA
          weiB
          deWeiA
          deWeiB
          balA
          balB
        }
      }
  `
  let data :any[] = []

  let error = false
  // let skip = 0
  let allFound = false
  let BlockNumber = projectConfig.startBlockNum
  try {
    while (!allFound) {
      const {loading, error, data: poolDatas} = await client.query({
        query: pools,
        variables: {
          BlockNumber
        },
        fetchPolicy: 'cache-first',
      })
      if (!loading) {
        // skip += 1000
        if (poolDatas.historicalPrices.length > 0) {
          BlockNumber += 1000
        }
        if (poolDatas.historicalPrices.length < 1000 || error) {
          allFound = true
        }
        if (poolDatas) {
          data = data.concat(poolDatas.historicalPrices)
        }
      }
    }
  } catch {
    error = true
  }
  if (data) {
    return { swaps: data, error: false, loading: false }
  } else {
    return { swaps: [], error: error }
  }
}