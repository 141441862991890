// @ts-nocheck
import Web3 from 'web3'
import bPOOL from './abi/bpool'
import {bpoolTOken, Config} from './config';

const web3: Web3 = new Web3(Web3.givenProvider)

const bPOOLContract = new web3.eth.Contract(bPOOL, bpoolTOken)

interface DenormalizedWeightAndGetbalance {
  balanceA: string;
  balanceB: string;
  weightA: string;
  weightB: string;
}

export const getDenormalizedWeightAndGetbalance = async () => {
  let dWG: DenormalizedWeightAndGetbalance = {
    weightA: await bPOOLContract.methods.getDenormalizedWeight(Config.bPoolTokenIn).call(),
    weightB: await bPOOLContract.methods.getDenormalizedWeight(Config.bPoolTokenOut).call(),
    balanceA: await bPOOLContract.methods.getBalance(Config.bPoolTokenIn).call(),
    balanceB: await bPOOLContract.methods.getBalance(Config.bPoolTokenOut).call()
  }
  return dWG
}